import React, { useState } from 'react';
import Truncate from 'react-truncate';

const TruncateText = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const data =
    props.isOpen || isOpen ? (
      <Truncate lines={1} ellipsis={<span>...</span>}>
        <div dangerouslySetInnerHTML={{ __html: props.children }} />
      </Truncate>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: props.children }} />
    );

  return data;
};

export default TruncateText;
