import React, { useState } from 'react';
import SpeakersList from './SpeakersList';
import StickyWarning from './StickyWarning';
import VizSensor from 'react-visibility-sensor';

const Isi = (props) => {
  const [isVisibleIsi, setIsVisibleIsi] = useState(true);

  return (
    <div className="isi">
      {!isVisibleIsi &&
        <StickyWarning />
      }
      <div className="references__line">
        Faculty are paid speakers presenting on behalf of Jazz Pharmaceuticals, Inc.{' '}
      </div>
      <div className="references__line">
        <span className="title--black">References:</span> <b>1.</b>XYWAV [package insert]. Palo
        Alto, CA: Jazz Pharmaceuticals, Inc; 2020. <b>2.</b>Thorpy MJ. <i>CNS Drugs.</i>{' '}
        2020;34(1):9-27. <b>3.</b>Data on File (JZP258-2020-008). Jazz Pharmaceuticals, Inc.
      </div>
      <SpeakersList
        tz={props.tz}
        api_url={props.api_url}
        events={props.events}
        selected={props.selected}
        handleEventClick={props.handleEventClick}
      />
      <VizSensor
        partialVisibility
        onChange={(isVisible) => {
          setIsVisibleIsi(isVisible);
        }}
      >
        <div>
          <div className="title--purple references__line">IMPORTANT SAFETY INFORMATION</div>
          <div className="border--black references__line">
            <center>WARNING: CENTRAL NERVOUS SYSTEM DEPRESSION and ABUSE AND MISUSE.</center>
            <table>
              <tbody>
                <tr>
                  <td className="table__bullet">&bull;</td>
                  <td>
                    <div className="title--black--underline">Central Nervous System Depression</div>
                    XYWAV is a CNS depressant. Clinically significant respiratory depression and
                    obtundation may occur in patients treated with XYWAV at recommended doses. Many
                    patients who received XYWAV during clinical trials in narcolepsy were receiving
                    CNS stimulants.
                  </td>
                </tr>
                <tr>
                  <td className="table__bullet">&bull;</td>
                  <td>
                    <div className="title--black--underline">Abuse and Misuse</div>
                    The active moiety of XYWAV is oxybate or gamma-hydroxybutyrate (GHB). Abuse or
                    misuse of illicit GHB, either alone or in combination with other CNS
                    depressants, is associated with CNS adverse reactions, including seizure,
                    respiratory depression, decreases in the level of consciousness, coma, and
                    death.
                  </td>
                </tr>
              </tbody>
            </table>
            Because of the risks of CNS depression and abuse and misuse, XYWAV is available only
            through a restricted program under a Risk Evaluation and Mitigation Strategy (REMS)
            called the Xywav and Xyrem REMS.
          </div>
          <div className="references__line">
            <div className="title--black--underline">Contraindications</div>
            XYWAV is contraindicated in combination with sedative hypnotics or alcohol and in
            patients with succinic semialdehyde dehydrogenase deficiency.
          </div>
          <div className="references__line">
            <div className="title--black--underline small_margin_bottom">
              Warnings and Precautions
            </div>
            <div className="small_margin_bottom">
              &bull; CNS Depression: Use caution when considering the concurrent use with other CNS
              depressants. If concurrent use is required consider dose reduction or discontinuation
              of one or more CNS depressants (including XYWAV). Consider interrupting XYWAV
              treatment if short-term opioid use is required. After first initiating treatment and
              until certain that XYWAV does not affect them adversely, caution patients against
              hazardous activities requiring complete mental alertness or motor coordination such as
              operating hazardous machinery, including automobiles or airplanes. Also caution
              patients against hazardous activities for at least 6 hours after taking XYWAV.
              Patients should be queried about CNS depression-related events upon initiation of
              XYWAV therapy and periodically thereafter.
            </div>
            <div className="small_margin_bottom">
              &bull; Abuse and Misuse: XYWAV is a Schedule III controlled substance. The rapid onset
              of sedation, coupled with the amnestic features of GHB particularly when combined with
              alcohol, has proven to be dangerous for the voluntary and involuntary user (eg,
              assault victim).
            </div>
            <div className="small_margin_bottom">
              &bull; Respiratory Depression and Sleep-Disordered Breathing: XYWAV may impair
              respiratory drive, especially in patients with compromised respiratory function. In
              overdoses of oxybate and with illicit use of GHB, life-threatening respiratory
              depression has been reported. Increased apnea and reduced oxygenation may occur with
              XYWAV administration in adult and pediatric patients. A significant increase in the
              number of central apneas and clinically significant oxygen desaturation may occur in
              patients with obstructive sleep apnea treated with XYWAV. Prescribers should be aware
              that sleep-related breathing disorders tend to be more prevalent in obese patients, in
              men, in postmenopausal women not on hormone replacement therapy, and among patients
              with narcolepsy.
            </div>
            <div className="small_margin_bottom">
              &bull; Depression and Suicidality: In a randomized-withdrawal clinical trial in adult
              patients with narcolepsy (n=201), depression and depressed mood were reported in
              patients treated with XYWAV. In most cases, no change in XYWAV treatment was required.
              In clinical trials of Xyrem (same active moiety as XYWAV) in adult patients with
              narcolepsy (n=781), depression was reported by 7% of Xyrem-treated patients, with four
              patients (&lt;1%) discontinuing because of depression. In the pediatric clinical trial
              with Xyrem in patients with narcolepsy (n=104), one patient experienced suicidal
              ideation, and two patients reported depression while taking XYREM. Monitor patients
              for the emergence of increased depressive symptoms and/or suicidality while taking
              XYWAV, which require careful and immediate evaluation.
            </div>
            <div className="small_margin_bottom">
              &bull; Other Behavioral or Psychiatric Adverse Reactions: Monitor patients for
              impaired motor/cognitive function or the emergence of or increase in anxiety and/or
              confusion. The emergence or increase in the occurrence of behavioral or psychiatric
              events in patients taking XYWAV should be carefully monitored.
            </div>
            <div className="small_margin_bottom">
              &bull; Parasomnias: In a randomized-withdrawal clinical trial, parasomnias, including
              sleepwalking were reported in adult patients treated with XYWAV. Parasomnias,
              including sleepwalking, also have been reported in a pediatric clinical trial with
              sodium oxybate (same active moiety as XYWAV) and in postmarketing experience with
              sodium oxybate. Episodes of sleepwalking should be fully evaluated and appropriate
              interventions considered.
            </div>
            <div className="title--black--underline small_margin_bottom">
              {' '}
              Most Common Adverse Reactions
            </div>
            <div className="small_margin_bottom">
              In the adult clinical trial, in patients with narcolepsy, the most common adverse
              reactions (incidence ≥5% of XYWAV-treated patients) were headache, nausea, dizziness,
              decreased appetite, parasomnia, diarrhea, hyperhidrosis, anxiety, and vomiting.
            </div>
            In the pediatric clinical trial with Xyrem (same active moiety as XYWAV), that included
            pediatric patients 7 years of age and older with narcolepsy, the most common adverse
            reactions (≥5%) were nausea (20%), enuresis (19%), vomiting (18%), headache (17%),
            weight decreased (13%), decreased appetite (9%), dizziness (8%), and sleepwalking (6%).
            The safety profile in pediatric patients with XYWAV is expected to be similar to that of
            adult patients treated with XYWAV and to that of pediatric patients treated with Xyrem.
            <div>&nbsp;</div>
            <div className="small_margin_bottom">
              <span className="title--black small_margin_bottom">
                <i>DISCLAIMER: </i>
              </span>{' '}
              Jazz Pharmaceuticals encourages questions during this educational program. Please be
              advised, however, that if a question involves an off-label use of XYWAV, the company
              adheres to the FDA’s 2011 guidance on responding to off-label information requests
              which does not permit the speaker to respond to off-label questions about XYWAV during
              this program.
            </div>
            <div>
              <i>
                Questions about off-label uses of XYWAV may be directed to our Medical Information
                department at 1-800-520-5568 or via email to jazzpharma@medcomsol.com.
              </i>
            </div>
          </div>
          <div className="border--yellow references__line">
            <div className="small_margin_bottom">
              Please note that there are no certified continuing medical education credits approved
              for this program.
            </div>
            <div className="small_margin_bottom">
              Jazz Pharmaceuticals is committed to the principles of the Pharmaceutical Research and
              Manufacturers of America (PhRMA) Code on Interactions with Healthcare Professionals.
              As part of our commitment to that code, we cannot pay for any costs incurred for
              travel or food of spouses or guests of any program participants, and any such spouses
              or guests may not attend any of the program’s meetings or events. We appreciate your
              understanding in this regard.
            </div>
            <div className="small_margin_bottom">
              Due to state regulations, prescribers licensed in Minnesota and healthcare
              professionals practicing in Vermont are not able to receive a meal at the dinner
              program. Meals may be reportable based on various state and federal laws. Guests are
              not permitted to attend any portion of the meeting or meals hosted by Jazz
              Pharmaceuticals.
            </div>
          </div>
          <b>
            Please see full{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black' }}
              href="https://pp.jazzpharma.com/pi/xywav.en.USPI.pdf"
            >
              Prescribing Information
            </a>
            , including BOXED Warning.
          </b>
          <div className="footer-section">
            <div className="footer-logo-text">
              <img
                src={require('./img/Asset-1@4x.png')}
                alt="Jazz Pharmaceuticals"
                style={{ width: 150 }}
              />
            </div>

            <div className="footer-links">
              <div>
                <a
                  href="https://www.jazzpharma.com/privacy-statement/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Statement
                </a>
              </div>
              <div style={{ width: 30 }}>&nbsp;</div>
              <div>
                <a
                  href="https://www.jazzpharma.com/terms-of-use/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </div>
            </div>
            <div className="footer-links">
              <div>&copy;2020 Jazz Pharmaceuticals plc or its subsidiaries</div>
              <div style={{ width: 10 }}>&nbsp;</div>
              <div>US-XYW-2000263 REV1120</div>
            </div>
            <div style={{ height: 15 }}>&nbsp;</div>
          </div>{' '}
        </div>
      </VizSensor>
    </div>
  );
};
export default Isi;
