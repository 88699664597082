import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

function StickyWarning(props) {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <div className="sticky-warning" style={{ bottom: isHidden ? -150 : 0 }}>
      <FontAwesomeIcon
        icon={isHidden ? faChevronUp : faChevronDown}
        style={{
          color: 'rgb(239, 60, 77)',
          position: 'absolute',
          top: 10,
          right: 20,
          fontSize: 15
        }}
        onClick={() => {
          setIsHidden(!isHidden);
        }}
      />
      <div className="text">
        <div className="title--purple references__line">IMPORTANT SAFETY INFORMATION</div>
        <div className="border--black references__line">
          <center>WARNING: CENTRAL NERVOUS SYSTEM DEPRESSION and ABUSE AND MISUSE.</center>
          <table>
            <tbody>
              <tr>
                <td className="table__bullet">•</td>
                <td>
                  <div className="title--black--underline">Central Nervous System Depression</div>
                  XYWAV is a CNS depressant. Clinically significant respiratory depression and
                  obtundation may occur in patients treated with XYWAV at recommended doses. Many
                  patients who received XYWAV during clinical trials in narcolepsy were receiving
                  CNS stimulants.
                </td>
              </tr>
              <tr>
                <td className="table__bullet">•</td>
                <td>
                  <div className="title--black--underline">Abuse and Misuse</div>The active moiety
                  of XYWAV is oxybate or gamma-hydroxybutyrate (GHB). Abuse or misuse of illicit
                  GHB, either alone or in combination with other CNS depressants, is associated with
                  CNS adverse reactions, including seizure, respiratory depression, decreases in the
                  level of consciousness, coma, and death.
                </td>
              </tr>
            </tbody>
          </table>
          Because of the risks of CNS depression and abuse and misuse, XYWAV is available only
          through a restricted program under a Risk Evaluation and Mitigation Strategy (REMS) called
          the Xywav and Xyrem REMS.
        </div>
      </div>
    </div>
  );
}

export default StickyWarning;
