import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import listPlugin from '@fullcalendar/list';
import moment from 'moment-timezone';
import Isi from './Isi';
import Modal from 'react-awesome-modal';
import $ from 'jquery';
import TruncateText from './TruncateText';
import tzGroups from './tzGroups';

const api_url = 'https://www.xywavspeakerprograms.com/api';
// if (process.env.NODE_ENV === 'development') {
//   api_url = 'http://jazzy-cal-api.beta.visual-a.com/api'
// }

const getTz = (userTz) => {
  let tz = 'America/New_York';
  for (const timeZone in tzGroups) {
    if (tzGroups[timeZone].includes(userTz)) {
      tz = timeZone;
      break;
    }
  }
  return tz;
};
export default class DemoApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekendsVisible: true,
      isModalOpen: false,
      events: [],
      toggleBio: true,
      view: 'dayGridMonth',
      tz: getTz(moment.tz.guess()),
      modalWidth: window.innerHeight > 768 ? 600 : 600,
      modalHeight: 400,
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      selectedEvent: false,
      dayOrDateComparison: 'day'
    };
    this.calendarRef = React.createRef();
    this.handleEventClick = this.handleEventClick.bind(this);
    this.renderModal = this.renderModal.bind(this);
  }

  async componentDidMount() {
    const that = this;
    this.calendarApi = this.calendarRef.current.getApi();

    const eventsJson = await fetch(`${api_url}/events`);
    const eventsList = await eventsJson.json();

    const events = eventsList.map((eventObj, idx) => {
      const momentDate = moment.tz(eventObj['start_date'], 'America/New_York');

      const isDr =
        (eventObj['presenter']['first_name'] === 'Sarah' &&
          eventObj['presenter']['last_name'] === 'Aguilar') ||
        (eventObj['presenter']['first_name'] === 'Rachel' &&
          eventObj['presenter']['last_name'] === 'Dyches')
          ? ''
          : 'Dr. ';

      return {
        title:
          isDr + eventObj['presenter']['first_name'][0] + ' ' + eventObj['presenter']['last_name'],
        id: eventObj['id'],
        regLink: eventObj['registration_link'],
        start: momentDate.tz(that.state.tz).format(),
        isDr: isDr,
        doctor:
          isDr + eventObj['presenter']['first_name'] + ' ' + eventObj['presenter']['last_name'],
        specialty: eventObj['presenter']['specialty'],
        credentials: eventObj['presenter']['credentials'],
        affiliation: eventObj['presenter']['affiliation'],
        city: eventObj['presenter']['city'],
        state: eventObj['presenter']['state'],
        bio: eventObj['presenter']['bio'],
        momentDate
      };
    });

    this.setState({
      events
    });

    $(document).ready(function () {
      // RYANS STUFF - NEED THIS
      $('.fc-toolbar-chunk').eq(0).prepend(`
        <select class="select__timezone">
          <option value="America/New_York">Eastern Time (ET)</option>
          <option value="America/Chicago">Central Time (CT)</option>
          <option value="America/Denver">Mountain Time (MT)</option>
          <option value="America/Los_Angeles">Pacific Time (PT)</option>
        </select>
      `);

      $('.fc-toolbar-chunk select').val(that.state.tz);
      $('select.select__timezone').change(function () {
        that.setState({ tz: $(this).val() });
        that.calendarApi.removeAllEventSources();
        const eventObjs = that.state.events.map((eventObj, i) => {
          eventObj['start'] = eventObj['momentDate']
            .tz($(this).val())
            .format('YYYY-MM-DDTHH:mm:ss');
          return eventObj;
        });
        that.calendarApi.addEventSource(eventObjs);
        that.setState({ events: eventObjs });
      });

      $('.fc-toolbar-chunk:first-child').append(
        `<button class="fc-today-button" style="margin-left: 20px;">today</button>`
      );
      $('.fc-today-button').removeClass('fc-state-disabled').prop('disabled', false);

      $(document).on('click', '.fc-today-button', function () {
        that.calendarApi.today();
      });

      that.calendarApi.gotoDate(moment('2021-1-01').toISOString());
      // END RYANS STUFF - NEED THIS
    });

    $(window).on('load resize', function () {
      if (window.innerWidth <= 768) {
        $('.fc-dayGridMonth-button').parent().hide();
        // 'dayGridMonth,timeGridWeek,timeGridDay'
        that.calendarApi.changeView('listWeek');
        that.setState({
          header: {
            left: 'prev,next',
            center: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            right: 'title'
          },
          modalHeight: '100%',
          modalWidth: '100%'
        });
      } else {
        that.calendarApi.changeView('dayGridMonth');
        that.setState({
          header: {
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          },
          modalHeight: 400,
          modalWidth: 600
        });
      }
    });
  }

  renderModal = (event) => {
    const { selectedEvent, dayOrDateComparison } = this.state;
    let eventId;
    let content = this.state.events.map((eventObj, idx) => {
      let selectedEventTime;
      if (!selectedEvent) {
        return;
      }

      if (selectedEvent.event) {
        eventId = selectedEvent.event.id;
        selectedEventTime = selectedEvent.event.startStr;
      } else {
        eventId = selectedEvent.id;
        selectedEventTime = selectedEvent.start_date;
      }

      const condition =
        dayOrDateComparison === 'day'
          ? moment(eventObj.start, 'YYYY-MM-DD').isSame(moment(selectedEventTime, 'YYYY-MM-DD'))
          : eventId === eventObj.id;

      if (condition) {
        const person =
          eventObj.doctor === 'Sarah Aguilar' || eventObj.doctor === 'Rachel Dyches'
            ? eventObj.doctor + ',<br />' + eventObj.credentials
            : eventObj.doctor;

        return (
          <div
            key={idx}
            className={`modal__event ${
              eventObj.id === parseInt(eventId) ? 'modal_event--active' : ''
            }`}
          >
            <div className="flex death">
              <div className="modal__time">{eventObj.momentDate.format('h:mm A z')}</div>
              <div className="modal__doctor" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                <div key={Date()} dangerouslySetInnerHTML={{ __html: person }} />
              </div>
              <div>{eventObj.specialty}</div>

              <div>{eventObj.affiliation}</div>
              <div>
                {eventObj.city}, {eventObj.state}
              </div>
            </div>
            <div
              onClick={() => {
                this.setState({ toggleBio: !this.state.toggleBio });
              }}
              className="bio"
              style={{ height: 'auto' }}
            >
              <span style={{ fontWeight: 'bold' }}>Bio:</span>
              <br />
              <TruncateText isOpen={false}>{eventObj.bio}</TruncateText>
            </div>
            <a
              style={{ display: 'block' }}
              href={eventObj['regLink']}
              target="_blank"
              className="modal__button"
            >
              Register
            </a>
          </div>
        );
      }
      return false;
    });

    let titleStr;
    if (selectedEvent.event) {
      titleStr = selectedEvent.event.startStr;
    } else {
      titleStr = selectedEvent.start_date;
    }
    const title = <h3>{moment(titleStr).format('dddd, MMMM Do YYYY')}</h3>;
    if (!content.length) {
      content = <div>Sorry, there are no events on this date.</div>;
    }
    const that = this;
    return (
      <div className="modal__content" style={{ paddingTop: 20 }}>
        <img
          onClick={() => {
            that.setState({ isModalOpen: false });
          }}
          style={{
            position: 'absolute',
            right: 20,
            top: 20,
            cursor: 'pointer',
            height: 10,
            width: 'auto'
          }}
          src={require('./img/close.png')}
        />
        {title}
        <div style={{ height: 400, overflowY: 'auto' }}>{content}</div>
      </div>
    );
  };

  render() {
    const that = this;

    return (
      <section>
        <img style={{ width: '100%' }} src={require('./img/calendar-header.png')} alt="" />
        <div>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 12, marginTop: 10 }}>
              <div className="title--purple">Indication</div>
              XYWAV oral solution, 0.5 g/mL total salts (equivalent to 0.413 g/mL of oxybate) is
              indicated for the treatment of cataplexy or excessive daytime sleepiness (EDS) in
              patients 7 years of age and older with narcolepsy.
            </div>
            <br />
            <br />
            Thank you for your interest in attending an upcoming <b>virtual speaker program.</b>
            Please select an option below to view additional details or to register.{' '}
            <b>Please choose a time zone prior to viewing program options.</b>
            <br />
            <br />
            Additional information on our expert speakers can be found below. Please scroll down to
            view.
          </div>
          <div className="flex flex__column">
            <FullCalendar
              timeZone={this.state.tz}
              ref={this.calendarRef}
              plugins={[
                // momentTimezonePlugin,
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin
              ]}
              headerToolbar={that.state.header}
              windowResize={(arg) => {
                if (window.innerWidth <= 768) {
                  this.changeView('listWeek');
                  that.setState({
                    header: {
                      left: 'prev,next',
                      center: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                      right: 'title'
                    }
                  });
                } else {
                  this.changeView('dayGridMonth');
                  that.setState({
                    header: {
                      left: 'prev,next',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    }
                  });
                }
              }}
              initialView={this.state.view}
              editable={false}
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: 'short'
              }}
              allDaySlot={false}
              dayMaxEvents={3}
              weekends={this.state.weekendsVisible}
              events={this.state.events}
              // initialEvents={this.state.events} // alternatively, use the `events` setting to fetch from a feed
              eventClick={(calEvent) => {
                that.setState({
                  isModalOpen: !that.state.isModalOpen,
                  selectedEvent: calEvent,
                  dayOrDateComparison: 'day'
                });
              }}
            />
          </div>

          <Isi
            api_url={api_url}
            events={this.state.events}
            selected={this.state.selected}
            handleEventClick={this.handleEventClick}
            tz={this.state.tz}
          />
          <Modal
            visible={this.state.isModalOpen}
            effect="fadeInUp"
            maxHeight="80%"
            width={this.state.modalWidth.toString()}
            // style={{ background: 'transparent', height: '100vh' }}
            onClickAway={() => this.setState({ isModalOpen: !this.state.isModalOpen })}
          >
            <div style={{ overflowY: 'auto', background: '#ac96c7' }}>
              {this.state.selectedEvent && this.renderModal()}
            </div>
          </Modal>
        </div>
      </section>
    );
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible
    });
  };

  handleEventClick = (selectedEvent) => {
    this.setState({
      selectedEvent,
      isModalOpen: true,
      dayOrDateComparison: ''
    });
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    });
  };
}
