import React, { useState, useEffect, useLayoutEffect } from 'react';
import SpeakerCard from './SpeakerCard';

const SpeakersList = (props) => {
  const [presenters, setPresenters] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const presenterJson = await fetch(`${props.api_url}/presenters`);
      const presenters = await presenterJson.json();
      setPresenters(presenters);
    };
    getData();
  }, []);



  return (
    <div className="speakers-list">
      <div className="title--red">Expert Speakers</div>
      {presenters.map((s, idx) => (
        <SpeakerCard
          tz={props.tz}
          key={idx}
          speaker={s}
          selected={props.selected}
          handleEventClick={props.handleEventClick}
        />
      ))}
    </div>
  );
};

export default SpeakersList;
