import React, { useState } from 'react';
import moment from 'moment';
import TruncateText from './TruncateText';

const SpeakerCard = (props) => {
  const [toggleClicked, setToggleClicked] = useState(true);

  function toggleSpeaker() {
    setToggleClicked(!toggleClicked);
  }

  return (
    <div className="speaker">
      <div className="speaker-toggle" onClick={toggleSpeaker}>
        {toggleClicked ? '+' : '-'}
      </div>
      <div className="speaker-info">
        <div className="speaker-title">
          {props.speaker.first_name} {props.speaker.last_name}, {props.speaker.credentials}
        </div>
        <div onClick={toggleSpeaker}>
          <TruncateText isOpen={toggleClicked}>{props.speaker.bio}</TruncateText>
        </div>
        <div className="scheduled-text">Scheduled Program(s):</div>
        <div style={{ display: 'flex', alignItems: 'space-between' }}>
          {props.speaker.events.map((p, idx) => {
            const m = moment.tz(p['start_date'], 'America/New_York');
            const mtz = m.tz(props.tz).format('MMMM Do, h:mm a z');

            if (m > moment()) {
              return (
                <button
                  onClick={() => {
                    props.handleEventClick(p);
                  }}
                  key={idx}
                  style={{
                    marginRight: 10,
                    marginTop: 10,
                    borderRadius: 4,
                    borderWidth: 1,
                    background: 'transparent',
                    padding: 10,
                    cursor: 'pointer'
                  }}
                >
                  {mtz}
                </button>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default SpeakerCard;
