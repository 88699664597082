const tzGroups = {
  'America/New_York': [
    'America/Indiana/Indianapolis',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Iqaluit',
    'America/Kentucky/Louisville',
    'America/Nassau',
    'America/Nipigon',
    'America/Pangnirtung',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Thunder_Bay',
    'America/Toronto',
    'America/Tortola'
  ],
  'America/Chicago': [
    'America/Atikokan',
    'America/Bahia_Banderas',
    'America/Bogota',
    'America/Cancun',
    'America/Cayman',
    'America/Chicago',
    'America/Eirunepe',
    'America/Guayaquil',
    'America/Indiana/Knox',
    'America/Indiana/Tell_City',
    'America/Jamaica',
    'America/Lima',
    'America/Matamoros',
    'America/Menominee',
    'America/Merida',
    'America/Mexico_City',
    'America/Monterrey',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Panama',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Winnipeg'
  ],

  'America/Denver': [
    'America/Belize',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Denver',
    'America/Edmonton',
    'America/El_Salvador',
    'America/Guatemala',
    'America/Inuvik',
    'America/Managua',
    'America/Mazatlan',
    'America/Ojinaga',
    'America/Regina',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Yellowknife',
    'Pacific/Easter',
    'Pacific/Galapagos'
  ],
  'America/Los_Angeles': [
    'America/Creston',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Hermosillo',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Tijuana',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Anchorage',
    'America/Juneau',
    'America/Metlakatla',
    'America/Nome',
    'America/Sitka',
    'America/Yakutat',
    'Pacific/Pitcairn'
  ]
};

export default tzGroups;
